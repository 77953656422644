// src/pages/Home.js
import React from "react";
import { Container } from "react-bootstrap";
import HeroSection from "../components/HeroSection";
import ServicesGrid from "../components/ServicesGrid";
import Certifications from "../components/Certifications";
import CTA from "../components/CTA";
import FunFacts from "./FunFacts";
function Home() {
  return (
    <Container className="my-5">
      <HeroSection />
      <ServicesGrid />
      <Certifications />
      <CTA label="Request a Consultation" link="/contact" />
      <CTA label="Learn More About Our Services" link="/services" />
      <CTA label="Fun Facts About This Site" link="/funfacts" />
    </Container>
  );
}

export default Home;
