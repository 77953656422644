// // src/components/CapabilityStatement.js
import React from "react";

function CapabilityStatement() {
  return (
    <div className="my-5 text-center">
      <h2>Our Capabilities Statement</h2>
      <div style={{ width: "100%", height: "700px" }}>
        <iframe
          src="./Vedantic_Solutions_Capability_Statement.pdf"
          style={{ width: "100%", height: "100%", border: "none" }}
          title="Capabilities Statement"
        >
          Your browser does not support PDFs.
          <a href="./Vedantic_Solutions_Capability_Statement.pdf" download>
            Download the PDF
          </a>
        </iframe>
      </div>
    </div>
  );
}

export default CapabilityStatement;

// src/components/CaseStudies.js
// import React from "react";
// import { Container, Button } from "react-bootstrap";

// function CapabilityStatement() {
//   return (
//     <Container className="my-5">
//       <h2 className="text-center mb-4">Our Capabilities Statement</h2>
//       <div
//         style={{
//           position: "relative",
//           border: "1px solid #ddd",
//           borderRadius: "8px",
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//           overflow: "hidden",
//           height: "700px",
//         }}
//       >
//         {/* Embed PDF */}
//         <iframe
//           src="../../../public/Vedantic_Solutions_Capability_Statement.pdf"
//           style={{
//             width: "100%",
//             height: "100%",
//             border: "none",
//           }}
//           title="Capabilities Statement"
//         />
//       </div>

//       {/* Download Button */}
//       <div className="text-center mt-3">
//         <Button
//           href="../../../public/Vedantic_Solutions_Capability_Statement.pdf"
//           download
//           variant="primary"
//         >
//           Download Capabilities Statement
//         </Button>
//       </div>
//     </Container>
//   );
// }

// export default CapabilityStatement;
