import React from "react";

function FunFacts() {
  return (
    <div className="container my-5 text-center">
      <h2>Fun Facts About This Site</h2>
      <p>
        🎉 This entire site is <strong>completely serverless</strong>! 🚀
      </p>
      <ul className="list-unstyled">
        <li>✅ Hosted on AWS Amplify</li>
        <li>✅ React-based frontend</li>
        <li>✅ Zero traditional servers</li>
        <li>✅ Static assets served via a global CDN</li>
        <li>✅ AWS API Gateway and Lambda functions for backend</li>
      </ul>
      <p>
        Serverless architecture allows us to deliver high performance, scalability, and reduced costs without managing any servers!
      </p>
    </div>
  );
}

export default FunFacts;