// src/pages/Services.js
import React from "react";
import { Container } from "react-bootstrap";
import ServiceDescriptions from "../components/service/ServiceDescriptions";
import IndustriesServed from "../components/service/IndustriesServed";
import CapabilityStatement from "../components/service/CapabilityStatement";
import Certifications from "../components/service/Certifications";

function Services() {
  return (
    <Container className="my-5">
      <ServiceDescriptions />
      <IndustriesServed />
      <CapabilityStatement />
      <Certifications />
    </Container>
  );
}

export default Services;
