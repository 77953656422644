// src/components/IndustriesServed.js
import React from "react";
import { Card } from "react-bootstrap";

function IndustriesServed() {
  const industries = [
    "Information Technology",
    "Logistics and Supply Chain",
    "Defense",
    "General Data and Analytics",
  ];

  return (
    <Card className="my-5 p-4 text-center bg-light">
      <Card.Body>
        <Card.Title as="h2">Industries Served</Card.Title>
        <Card.Text>
          We specialize in delivering services across various industries
          tailored to government clients.
        </Card.Text>
        <ul className="list-unstyled">
          {industries.map((industry, index) => (
            <li key={index}>{industry}</li>
          ))}
        </ul>
      </Card.Body>
    </Card>
  );
}

export default IndustriesServed;
