// src/components/Certifications.js
import React from "react";
import { Card } from "react-bootstrap";

function Certifications() {
  return (
    <Card className="text-center my-5 p-4 bg-light">
      <Card.Body>
        <Card.Title as="h2">Certifications & Compliance</Card.Title>
        <Card.Text>
          Our certifications and compliance standards ensure we meet all federal
          requirements for government projects:
        </Card.Text>
        <ul className="list-unstyled">
          <li>SDVOSB Certification</li>
        </ul>
      </Card.Body>
    </Card>
  );
}

export default Certifications;
